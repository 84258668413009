import * as React from "react";
import HeaderMenu from "../common/HeaderMenu.js";
import { useHistory } from "react-router-dom";
import CheckoutProgressBar from "./CheckoutProgressBar";
import CustomerAmountDetails from "./CustomerAmountDetails";
import {
  isValidName,
  isValidEmail,
  isValidAddress,
  isValidPostcode,
  isValidPhone,
} from "../../utils/regexUtils";
import {
  DISCOUNT_AMOUNT,
  PROMOCODE_DISCOUNT,
  SET_CUSTOMER_BILLING_DETAILS,
  SET_TOTAL_AMOUNT,
} from "../../constants/actionTypes";
import ToggleButton from "react-toggle-button";
import AdminHeaderMenu from "../common/AdminHeaderMenu.js";
import { Button, Spinner } from "react-bootstrap";
import { promoCodeApiCall } from "../../serviceCalls/promoCodeApiCall.js";

const CustomerShippingInformation = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const {
    cartDetails,
    subTotalAmount,
    totalVatAmount,
    totalAmount,
    shippingCost,
    config,
    promocodeDiscount,
    promoCodeError,
    warehouseAttr
  } = applicationState;


  const [applyCoupon, setApplyCoupon] = React.useState(false);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  React.useEffect(() => {
    const cartData = JSON.parse(window.sessionStorage.getItem("cart"));
    if (promocodeDiscount || promoCodeError) {
      if(!promoCodeError && promocodeDiscount == 619 && shippingCost == 9.95){
        settingAmountDetails(shippingCost, Number((subTotalAmount +
          totalVatAmount +
          Number(shippingCost)) * (promocodeDiscount === 619 ? 0 : promocodeDiscount / 100)).toFixed(2), applicationState.warehouseAttr);
        setDiscountAmount(Number((subTotalAmount +
          totalVatAmount +
          Number(shippingCost)) * (promocodeDiscount === 619 ? 0 : promocodeDiscount / 100)).toFixed(2));
        }else if (!promoCodeError && promocodeDiscount != 10 && promocodeDiscount != 20) {
          settingAmountDetails(shippingCost, Number(promocodeDiscount).toFixed(2),applicationState.warehouseAttr);
          setDiscountAmount(Number(promocodeDiscount).toFixed(2));
          }
          else if (!promoCodeError && promocodeDiscount != 619 ) {
            settingAmountDetails(shippingCost, Number((subTotalAmount +
              totalVatAmount +
              Number(shippingCost)) * (promocodeDiscount === 619 ? 0 : promocodeDiscount / 100)).toFixed(2), applicationState.warehouseAttr)
            setDiscountAmount(Number((subTotalAmount +
              totalVatAmount +
              Number(shippingCost)) * (promocodeDiscount === 619 ? 0 : promocodeDiscount / 100)).toFixed(2));
            }
      else {
        settingAmountDetails(shippingCost, 0, applicationState.warehouseAttr);
        setDiscountAmount(0);
        }
        setApplyCoupon(false);
    }

    if (cartData) {
      setTempCart(cartData);
    }
  }, [promocodeDiscount, promoCodeError]);

  const [tempCart, setTempCart] = React.useState(cartDetails);
  const [shippingAddressDetails, setShippingAddressDetails] = React.useState({
    firstName: applicationState?.shippingAddressDetails?.firstName ?? "",
    lastName: applicationState?.shippingAddressDetails?.lastName ?? "",
    email: applicationState?.shippingAddressDetails?.email ?? "",
    phoneNo: applicationState?.shippingAddressDetails?.phoneNo ?? "",
    addressLineOne: applicationState?.shippingAddressDetails?.addressLineOne ?? "",
    addressLineTwo: applicationState?.shippingAddressDetails?.addressLineTwo ?? "",
    city: applicationState?.shippingAddressDetails?.city ?? "",
    postCode: applicationState?.shippingAddressDetails?.postCode ?? "",
    instagramId: applicationState?.shippingAddressDetails?.instagramId ?? "",
    promoCode: "",
    tradeOfBusiness:
      applicationState?.shippingAddressDetails?.tradeOfBusiness ?? "",
  });

  React.useEffect(() => {
    setShippingAddressDetails(applicationState.shippingAddressDetails)
  }, [applicationState.shippingAddressDetails]);

  const [shippingAddressDetailsError, setShippingAddressDetailsError] =
  React.useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    phoneNoError: "",
    addressLineOneError: "",
    addressLineTwoError: "",
    cityError: "",
    postCodeError: "",
    instagramIdError: "",
    tradeOfBusinessError: "",
  });

  const [billingAddressDetails, setBillingAddressDetails] = React.useState({
    firstNameBilling: applicationState?.shippingAddressDetails?.firstName ?? "",
    lastNameBilling: applicationState?.shippingAddressDetails?.lastName ?? "",
    emailBilling: applicationState?.shippingAddressDetails?.email ?? "",
    phoneNoBilling: applicationState?.shippingAddressDetails?.phoneNo ?? "",
    billingAddressLineOne: applicationState?.shippingAddressDetails?.billingAddressLineOne ?? "",
    billingAddressLineTwo: applicationState?.shippingAddressDetails?.billingAddressLineTwo ?? "",
    billingCity: applicationState?.shippingAddressDetails?.billingCity ?? "",
    billingPostCode: applicationState?.shippingAddressDetails?.postCode ?? "",
  });

  const [billingAddressDetailsError, setBillingAddressDetailsError] =
  React.useState({
    firstNameBillingError: "",
    lastNameBillingError: "",
    emailBillingError: "",
    phoneNoBillingError: "",
    billingAddressLineOneError: "",
    billingAddressLineTwoError: "",
    billingCityError: "",
    billingPostCodeError: "",
  });

  const [emptyCredentialsError, setEmptyCredentialsError] = React.useState("");
  const [isSameAddress, setIsSameAddress] = React.useState(
    applicationState?.isSameAddress ?? true
  );

  const onShippingFieldChange = (e) => {
    setShippingAddressDetails({
      ...shippingAddressDetails,
      [e.target.id]: e.target.value,
    });
    setShippingAddressDetailsError({
      ...shippingAddressDetailsError,
      [`${e.target.id}Error`]: "",
    });
  };

  const onBillingFieldChange = (e) => {
    setBillingAddressDetails({
      ...billingAddressDetails,
      [e.target.id]: e.target.value,
    });
    setBillingAddressDetailsError({
      ...billingAddressDetailsError,
      [`${e.target.id}Error`]: "",
    });
  };

  const toggleIsSameAddress = () => {
    setBillingAddressDetails({
      firstNameBilling: shippingAddressDetails.firstName,
      lastNameBilling: shippingAddressDetails.lastName,
      emailBilling: shippingAddressDetails.email,
      phoneNoBilling: shippingAddressDetails.phoneNo,
      billingPostCode: shippingAddressDetails.postCode,
      billingAddressLineOne: shippingAddressDetails.billingAddressLineOne,
      billingAddressLineTwo: shippingAddressDetails.billingAddressLineTwo,
      billingCity: shippingAddressDetails.billingCity,
    });
    setIsSameAddress(!isSameAddress);
  };

  const validateName = (e) => {
    const { id, value } = e?.target;
    switch (id) {
      case "firstName":
        setShippingAddressDetailsError({
          ...shippingAddressDetailsError,
          firstNameError: isValidName(value),
        });
        break;
      case "lastName":
        setShippingAddressDetailsError({
          ...shippingAddressDetailsError,
          lastNameError: isValidName(value),
        });
		break;
      case "firstNameBilling":
        setBillingAddressDetailsError({
          ...billingAddressDetailsError,
          firstNameBillingError: isValidName(value),
        });
		break;
      case "lastNameBilling":
        setBillingAddressDetailsError({
          ...billingAddressDetailsError,
          lastNameBillingError: isValidName(value),
        });
		break;
		default:
    }
  };

  const validateEmail = (e) => {
    const { id, value } = e?.target;
    if (id === "email") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        emailError: isValidEmail(value),
      });
    } else {
      setBillingAddressDetailsError({
        ...billingAddressDetailsError,
        emailBillingError: isValidEmail(value),
      });
    }
  };

  const validateAddress = (e) => {
    const { id, value } = e?.target;
    if (id === "addressLineOne") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        addressLineOneError: isValidAddress(value),
      });
    }
    else if(id === "addressLineTwoError") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        addressLineTwoError: isValidAddress(value),
      });
    }
    else if(id === "billingAddressLineOne") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        billingAddressLineOne: isValidAddress(value),
      });
    }
    else if(id === "billingAddressLineTwo") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        billingAddressLineTwo: isValidAddress(value),
      });
    }
  };

  const validatePostcode = (e) => {
    const { id, value } = e?.target;
    if (id === "postCode") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        postCodeError: isValidPostcode(value),
      });
    } else {
      setBillingAddressDetailsError({
        ...billingAddressDetailsError,
        billingPostCodeError: isValidPostcode(value),
      });
    }
  };

  const validatePhone = (e) => {
    const { id, value } = e?.target;
    if (id === "phoneNo") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        phoneNoError: isValidPhone(value),
      });
    } else {
      setBillingAddressDetailsError({
        ...billingAddressDetailsError,
        phoneNoBillingError: isValidPhone(value),
      });
    }
  };

  const validateSubmit = (e) => {
    if (
      shippingAddressDetailsError.firstNameError ||
      shippingAddressDetailsError.lastNameError ||
      shippingAddressDetailsError.emailError ||
      shippingAddressDetailsError.addressError ||
      shippingAddressDetailsError.addressLineOneError ||
      shippingAddressDetailsError.addressLineTwoError ||
      shippingAddressDetailsError.cityError ||
      shippingAddressDetailsError.postCodeError ||
      shippingAddressDetailsError.phoneNoError
    ) {
      e.preventDefault();
    } else if (
      shippingAddressDetails.firstName === "" ||
      shippingAddressDetails.lastName === "" ||
      shippingAddressDetails.email === "" ||
      shippingAddressDetails.addressLineOne === "" ||
      shippingAddressDetails.city === "" ||
      shippingAddressDetails.postCode === "" ||
      shippingAddressDetails.phoneNo === "" ||
      (!isSameAddress &&
        (billingAddressDetails.firstNameBilling === "" ||
          billingAddressDetails.lastNameBilling === "" ||
          billingAddressDetails.emailBilling === "" ||
          billingAddressDetails.phoneNoBilling === "" ||
          billingAddressDetails.billingAddressLineOne === "" ||
          billingAddressDetails.billingCity === "" ||
          billingAddressDetails.billingPostCode === ""))
    ) {
      e.preventDefault();
        let erroMsg = '';
        if(shippingAddressDetails.firstName === "") {
          erroMsg = 'First name is required.';
        }
        else if(shippingAddressDetails.lastName === "") {
          erroMsg = 'Last name is required.';
        }
        else if(shippingAddressDetails.email === "") {
          erroMsg = 'Email is required.';
        }
        else if(shippingAddressDetails.addressLineOne === "") {
          erroMsg = 'Shipping address line 1 is required.';
        }
        else if(shippingAddressDetails.phoneNo === "") {
          erroMsg = 'Phone No. is required.';
        }
        else if(shippingAddressDetails.city === "") {
          erroMsg = 'Shipping City is required.';
        }
        else if(shippingAddressDetails.postCode === "") {
          erroMsg = 'Shipping Post Code is required.';
        }

        if(!isSameAddress) {
          if(billingAddressDetails.firstNameBilling === "") {
            erroMsg = 'Billing First name is required.';
          }
          else if(billingAddressDetails.lastNameBilling === "") {
            erroMsg = 'Billing Last name is required.';
          }
          else if(billingAddressDetails.emailBilling === "") {
            erroMsg = 'Billing Email is required.';
          }
          else if(billingAddressDetails.phoneNoBilling === "") {
            erroMsg = 'Billing Phone No. is required.';
          }
          else if(billingAddressDetails.billingAddressLineOne === "") {
            erroMsg = 'Billing Address Line 1 is required.';
          }
          else if(billingAddressDetails.billingCity === "") {
            erroMsg = 'Billing City is required.';
          }
          else if(billingAddressDetails.billingPostCode === "") {
            erroMsg = 'Billing Postcode is required.';
          }
        }
      setEmptyCredentialsError(
        erroMsg
      );
    } else {
      dispatch({
        type: DISCOUNT_AMOUNT,
        payload: {
          discountAmount
        }
      });
      dispatch({
        type: SET_CUSTOMER_BILLING_DETAILS,
        payload: {
          shippingAddressDetails,
          billingAddressDetails,
          isSameAddress,
          cppCodeExist: document.getElementById('ccpCode')?.value ? document.getElementById('ccpCode').value : "",
          warehouse:warehouseAttr
        }
      });
      history.push("/customerpayment_info");
    }
  };

  const settingAmountDetails = (shippingCost, discountedAmount = 0, option) => {
    var altshipcost = shippingCost;
    var updatedsubtotal = subTotalAmount;
    if(!promoCodeError && promocodeDiscount == 619 && shippingCost == 9.95){
      updatedsubtotal = subTotalAmount - 9.95;
    }
    console.log("subtotal " + subTotalAmount - 9.95);
    console.log(localStorage.getItem('walking-selected-warehouse'));
    console.log(Number(updatedsubtotal) +
    Number(totalVatAmount) +
    Number(altshipcost) -
    Number(discountedAmount).toFixed(2));
    dispatch({
      type: SET_TOTAL_AMOUNT,
      payload: {
        altshipcost,
        shippingCost: altshipcost,
        subTotalAmount: updatedsubtotal,
        totalVatAmount: totalVatAmount,
        totalAmount: Number(updatedsubtotal) +
          Number(totalVatAmount) +
          Number(altshipcost) -
          Number(discountedAmount).toFixed(2),
        warehouseattr:option
      },
    });
  };

  const validatePromocode = (e) => {
    e.preventDefault();
    dispatch({
      type: PROMOCODE_DISCOUNT,
      payload: null,
      promoCodeError: ""
    });
    setApplyCoupon(true);
    if (shippingAddressDetails.promoCode != "") {
      let productQuantity = 0;
      const totalCost = Number(Number(subTotalAmount) + Number(totalVatAmount) + Number(shippingCost)).toFixed(2);
      cartDetails.forEach((cart) => {
        productQuantity = productQuantity + cart.quantity;
      });

      const itemList = applicationState?.cartDetails?.map((item) => [
        item.productid,
        item.quantity,
      ]);
      let productIds = Object.fromEntries(itemList);

      const promoCodeRequest = {
        promoCode: shippingAddressDetails.promoCode,
        email: config.email,
        totalCost: Number(totalCost).toFixed(2),
        productQuantity: productQuantity,
        productIds: productIds
      }
      promoCodeApiCall({ dispatch, authToken: config.authToken, promoCodeRequest: promoCodeRequest })
    }

  }
  const onNameChange = (e) => {
    let regex = /^[A-Za-z]+$/

    // check first char
    let first = e.target.value[0];

    if (first == undefined ) {
      setShippingAddressDetails({
        ...shippingAddressDetails,
        [e.target.id]: "",
      });
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        [`${e.target.id}Error`]: "",
      });

    }


    if (regex.test(e.target.value)) {
      setShippingAddressDetails({
        ...shippingAddressDetails,
        [e.target.id]: e.target.value.replace(/\s+/g, ''),
      });
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        [`${e.target.id}Error`]: "",
      });

    }


  }

  return (
    <div>
      <div>
        {config?.userType === "ADMIN" ? (
          <AdminHeaderMenu dispatch={dispatch} />
        ) : (
          <HeaderMenu dispatch={dispatch} cartCount={tempCart.length} />
        )}
      </div>
      <div
        id="checkout"
        className={` ${config?.userType === "ADMIN" ? "admin" : ""}`}
      >
        <div class="template_cart mt_30 mb_50">
          <div class="container">
            <CheckoutProgressBar
              progressItem="Billing"
              userType={config?.userType}
            />
            <div class="cart__content flex justify-content-between flex-wrap">
              <div className="left50">
                <div class="cart__left mb_30">
                  <h3 class="title_name">Shipping Address</h3>
                  <div class="shipingForm">
                    <form>
                      <div class="twoBlock_d">
                        <div class="form_div">
                          <label for="firstName">First Name *</label>
                          {shippingAddressDetailsError.firstNameError ? (
                            <span className="error">
                            {shippingAddressDetailsError.firstNameError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="firstName"
                            placeholder="First Name*"
                            onChange={(e) => onNameChange(e)}
                            // onBlur={(e) => validateName(e)}
                            value={shippingAddressDetails.firstName}
                            maxlength="25"
                          />
                        </div>
                        <div class="form_div">
                          <label for="lastName">Surname *</label>
                          {shippingAddressDetailsError.lastNameError ? (
                            <span className="error">
                            {shippingAddressDetailsError.lastNameError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="lastName"
                            placeholder="Last Name*"
                            onChange={(e) => onNameChange(e)}
                            // onBlur={(e) => validateName(e)}
                            value={shippingAddressDetails.lastName}
                            maxlength="25"
                          />
                        </div>
                      </div>
                      <div class="twoBlock_d">
                        <div class="form_div">
                          <label for="Email">Email *</label>
                          {shippingAddressDetailsError.emailError ? (
                            <span className="error">
                            {shippingAddressDetailsError.emailError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Email*"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validateEmail(e)}
                            value={shippingAddressDetails.email}
                            readOnly
                          />
                        </div>
                        <div class="form_div">
                          <label for="ContactNumber">Contact Number *</label>
                          {shippingAddressDetailsError.phoneNoError ? (
                            <span className="error">
                            {shippingAddressDetailsError.phoneNoError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="phoneNo"
                            id="phoneNo"
                            className="form-control"
                            placeholder="Contact No."
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validatePhone(e)}
                            value={shippingAddressDetails.phoneNo}
                            maxlength="11"
                          />
                        </div>
                      </div>
                      <div class="twoBlock_d">
                        <div class="form_div">
                          <label for="addressLineOne">Shipping Address Line 1*</label>
                          {shippingAddressDetailsError.addressLineOneError ? (
                            <span className="error">
                            {shippingAddressDetailsError.addressLineOneError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="addressLineOne"
                            id="addressLineOne"
                            placeholder="Address Line 1*"
                            className="form-control"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validateAddress(e)}
                            value={shippingAddressDetails.addressLineOne}
                          />
                        </div>
                        <div class="form_div">
                          <label for="addressLineTwo">Shipping Address Line 2 *</label>
                          {shippingAddressDetailsError.addressLineTwoError ? (
                            <span className="error">
                            {shippingAddressDetailsError.addressLineTwoError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="addressLineTwo"
                            id="addressLineTwo"
                            placeholder="Address Line 2"
                            className="form-control"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validateAddress(e)}
                            value={shippingAddressDetails.addressLineTwo}
                          />
                        </div>
                      </div>
                      <div class="twoBlock_d nowrapMob">
                        <div class="form_div">
                          <label for="PostCode">Post Code *</label>
                          {shippingAddressDetailsError.postCodeError ? (
                            <span className="error">
                            {shippingAddressDetailsError.postCodeError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="postCode"
                            id="postCode"
                            className="form-control"
                            placeholder="Post Code*"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validatePostcode(e)}
                            value={shippingAddressDetails.postCode}
                          />
                        </div>
                        <div class="form_div">
                          <label for="DeliveryAddress">City *</label>
                          {shippingAddressDetailsError.cityError ? (
                            <span className="error">
                            {shippingAddressDetailsError.cityError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="city"
                            id="city"
                            placeholder="City*"
                            className="form-control"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validateAddress(e)}
                            value={shippingAddressDetails.city}
                          />
                        </div>
                      </div>
                      <div class="twoBlock_d nowrapMob">
                        <div class="form_div">
                          <label for="CouponCode">Coupon Code</label>
                          <div className="promo-code">
                            <input
                              type="text"
                              name="promoCode"
                              id="promoCode"
                              className=""
                              placeholder="Coupon code"
                              onChange={(e) => onShippingFieldChange(e)}
                              onBlur={(e) => onShippingFieldChange(e)}
                              value={shippingAddressDetails.promoCode}
                              style={{width:'200px'}}
                            />
                            <div className="button-promocode">
                              {!applyCoupon &&
                                <button
                                  className="btn blue-btn"
                                  type="submit"
                                  onClick={(e) => validatePromocode(e)}
                                >
                                  APPLY
                                </button>
                              }
                              {applyCoupon && <Button
                                className="button-spinner"
                                disabled
                                variant="secondary"
                                type="submit">
                                <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                {`    Please Wait`}
                              </Button>}
                              {promoCodeError ? (
                                <>
                                <span className="error">
                                  {promoCodeError}
                                </span>
                                <br />
                                </>
                              ) : (
                                <React.Fragment />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="twoBlock_d">
                        <div class="form_div">
                          <label for="EASI">EASI Code  ( optional )</label>
                          <input
                            type="text"
                            name="cppCode"
                            id="ccpCode"
                            className="ccpCode"
                            placeholder="EASI code (optional)"
                            // onChange={(e) => onShippingFieldChange(e)}
                            // onBlur={(e) => onShippingFieldChange(e)}
                            // value={""}
                          />
                        </div>
                        <div class="form_div">
                          <label for="DeliveryAddress">Trade of business  </label>
                          <select
                            id="tradeOfBusiness"
                            className="select form-select"
                            name="tradeOfBusiness"
                            onChange={(e) => onBillingFieldChange(e)}
                            value={shippingAddressDetails.tradeOfBusiness}
                          >
                            <option value="Mobile Practitioners">
                            Mobile Practitioners
                            </option>
                            <option value="Prescriber">Prescriber</option>
                            <option value="Clinics">Clinics</option>
                            <option value="Training Academy">
                            Training Academy
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="form_div billing_check">
                        <div className="toggle mrt-20 pdb-30">
                          <span>
                            <ToggleButton
                              value={isSameAddress}
                              inactiveLabel={'NO'}
                              activeLabel={'YES'}
                              onToggle={() =>
                              toggleIsSameAddress(!isSameAddress)
                              }
                              thumbStyle={{ borderRadius: 2 }}
                              trackStyle={{ borderRadius: 2 }}
                            />
                          </span>
                          <span>Is billing address same as shipping address?</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                {!isSameAddress && (
                  <div class="cart__left mb_30">
                    <h3 className="title_name">Billing Address</h3>
                    <div class="shipingForm">
                      <form>
                        <div class="twoBlock_d">
                          <div class="form_div">
                            <label for="firstName">First Name *</label>
                            {billingAddressDetailsError.firstNameBillingError ? (
                              <span className="error">
                                {
                                billingAddressDetailsError.firstNameBillingError
                                }
                              </span>
                            ) : (
                            <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="firstNameBilling"
                              className="form-control"
                              id="firstNameBilling"
                              placeholder="First Name*"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateName(e)}
                              value={billingAddressDetails.firstNameBilling}
                            />
                          </div>
                          <div class="form_div">
                            <label for="firstName">Surname *</label>
                            {billingAddressDetailsError.lastNameBillingError ? (
                            <span className="error">
                              {
                              billingAddressDetailsError.lastNameBillingError
                              }
                            </span>
                            ) : (
                            <React.Fragment />
                            )}

                            <input
                            type="text"
                            name="lastNameBilling"
                            className="form-control"
                            id="lastNameBilling"
                            placeholder="Last Name*"
                            onChange={(e) => onBillingFieldChange(e)}
                            onBlur={(e) => validateName(e)}
                            value={billingAddressDetails.lastNameBilling}
                            />
                          </div>
                        </div>
                        <div class="twoBlock_d">
                          <div class="form_div">
                            <label for="firstName">Email *</label>
                            {billingAddressDetailsError.emailBillingError ? (
                            <span className="error">
                              {billingAddressDetailsError.emailBillingError}
                            </span>
                            ) : (
                            <React.Fragment />
                            )}
                            <input
                            type="text"
                            name="emailBilling"
                            id="emailBilling"
                            className="form-control"
                            placeholder="Email*"
                            onChange={(e) => onBillingFieldChange(e)}
                            onBlur={(e) => validateEmail(e)}
                            value={billingAddressDetails.emailBilling}
                            />
                          </div>
                          <div class="form_div">
                            <label for="firstName">Billing Contact No.</label>
                            {billingAddressDetailsError.phoneNoBillingError ? (
                            <span className="error">
                              {billingAddressDetailsError.phoneNoBillingError}
                            </span>
                            ) : (
                            <React.Fragment />
                            )}

                            <input
                            type="text"
                            name="phoneNoBilling"
                            id="phoneNoBilling"
                            className="form-control"
                            placeholder="Billing Contact No."
                            onChange={(e) => onBillingFieldChange(e)}
                            onBlur={(e) => validatePhone(e)}
                            value={billingAddressDetails.phoneNoBilling}
                            />
                          </div>
                        </div>
                        <div class="twoBlock_d">
                          <div class="form_div">
                            <label for="billingAddressLineOne">Billing Address Line 1*</label>
                            {shippingAddressDetailsError.billingAddressLineOneError ? (
                              <span className="error">
                              {shippingAddressDetailsError.billingAddressLineOneError}
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="billingAddressLineOne"
                              id="billingAddressLineOne"
                              placeholder="Billing Address Line 1*"
                              className="form-control"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateAddress(e)}
                              value={shippingAddressDetails.billingAddressLineOne}
                            />
                          </div>
                          <div class="form_div">
                            <label for="billingAddressLineTwo">Billing Address Line 2</label>
                            {shippingAddressDetailsError.billingAddressLineTwoError ? (
                              <span className="error">
                              {shippingAddressDetailsError.billingAddressLineTwoError}
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="billingAddressLineTwo"
                              id="billingAddressLineTwo"
                              placeholder="Billing Address Line 2"
                              className="form-control"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateAddress(e)}
                              value={shippingAddressDetails.billingAddressLineTwo}
                            />
                          </div>
                        </div>
                        <div className="twoBlock_d">
                          <div class="form_div">
                            <label for="firstName">Billing Post Code*</label>
                            {billingAddressDetailsError.billingPostCodeError ? (
                              <span className="error">
                                {
                                billingAddressDetailsError.billingPostCodeError
                                }
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="billingPostCode"
                              id="billingPostCode"
                              className="form-control"
                              placeholder="Billing Post Code*"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validatePostcode(e)}
                              value={billingAddressDetails.billingPostCode}
                            />
                          </div>
                          <div class="form_div">
                            <label for="billingCity">Billing City</label>
                            {shippingAddressDetailsError.billingCityError ? (
                              <span className="error">
                              {shippingAddressDetailsError.billingCityError}
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="billingCity"
                              id="billingCity"
                              placeholder="City*"
                              className="form-control"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateAddress(e)}
                              value={shippingAddressDetails.billingCity}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
					    <div class="cart__side mb_30">
                <CustomerAmountDetails
                  subTotalAmount={subTotalAmount}
                  finalVatAmount={totalVatAmount}
                  totalAmount={totalAmount}
                  shippingCost={promocodeDiscount && promocodeDiscount === 619 && shippingCost === 9.95 ? 9.95 : shippingCost}
                  changeShippingCost={(newShippingCost, option) =>
                  settingAmountDetails(newShippingCost, 0, option)
                  }
                  dispatch={dispatch}
                  discountAmount={discountAmount}
                  promocodeDiscount={promocodeDiscount}
                  shippingDisabled={promocodeDiscount && promocodeDiscount === 619 &&
                  shippingCost === 9.95 ? true : false}
                />
                <div className="action_btn summerybtmBtns text-center mt_30">
                  {emptyCredentialsError ? (
                    <div className="mb-4 error">{emptyCredentialsError}</div>
                  ) : (
                    <React.Fragment />
                  )}
                  <button
                    className="btn grey-btn m_8"
                    type="submit"
                    onClick={() => {
                      config?.userType === "ADMIN"
                      ? history.push("/place_order")
                      : history.push("/customercart_details");
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="btn pink-btn m_8"
                    type="submit"
                    onClick={validateSubmit}
                  >
                    Proceed to Pay
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default CustomerShippingInformation;
